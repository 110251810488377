import { default as _91id_93026xV306sVMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/chat/[id].vue?macro=true";
import { default as _91id_93pYapG9O45qMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/chat/embed/[id].vue?macro=true";
import { default as public4GJtPmUng8Meta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/chat/public.vue?macro=true";
import { default as indexUIQIuShWOqMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/index.vue?macro=true";
import { default as maintenance8iQhQut3JUMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/maintenance.vue?macro=true";
import { default as sandboxZtTrpNsnPLMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/sandbox.vue?macro=true";
import { default as signinGPtRMeBw4QMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/signin.vue?macro=true";
import { default as signoutduP3Ev3HYcMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/signout.vue?macro=true";
import { default as signup5xAOa9Jr2IMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/signup.vue?macro=true";
import { default as indexKw1dpQNGaXMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/analytics/index.vue?macro=true";
import { default as sessions2hH02EHdGsMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue?macro=true";
import { default as analyticsVW3i4afqtMMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/analytics.vue?macro=true";
import { default as _91id_93iKq2nZeBQEMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue?macro=true";
import { default as created3BFIQR5jQMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/chatbots/create.vue?macro=true";
import { default as _91id_93mRPea5CiXGMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue?macro=true";
import { default as indexNU9fC4xomrMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/chatbots/index.vue?macro=true";
import { default as documentst3pKOXThD3Meta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/documents.vue?macro=true";
import { default as indexEw7QpalauTMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/index.vue?macro=true";
import { default as postcheckoutRqNOz7DDCwMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/postcheckout.vue?macro=true";
import { default as resetPassword0WRR68Me43Meta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/resetPassword.vue?macro=true";
import { default as apipb5zvOpvq3Meta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/api.vue?macro=true";
import { default as indexPdvDVnl6V5Meta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/index.vue?macro=true";
import { default as integrationsiCDczpSpAfMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/integrations.vue?macro=true";
import { default as legallbc0EO6uz8Meta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/legal.vue?macro=true";
import { default as organization19qIryYeNlMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/organization.vue?macro=true";
import { default as planavwCWiD1ufMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/plan.vue?macro=true";
import { default as settingsrauyA8FyFqMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings.vue?macro=true";
import { default as supportc98SBv7CBdMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/support.vue?macro=true";
import { default as teamUOTiHBqqJsMeta } from "/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/team.vue?macro=true";
export default [
  {
    name: "chat-id___en",
    path: "/chat/:id()",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-id___de",
    path: "/de/chat/:id()",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-embed-id___en",
    path: "/chat/embed/:id()",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/chat/embed/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-embed-id___de",
    path: "/de/chat/embed/:id()",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/chat/embed/[id].vue").then(m => m.default || m)
  },
  {
    name: "chat-public___en",
    path: "/chat/public",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/chat/public.vue").then(m => m.default || m)
  },
  {
    name: "chat-public___de",
    path: "/de/chat/public",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/chat/public.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___en",
    path: "/maintenance",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___de",
    path: "/de/maintenance",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "sandbox___en",
    path: "/sandbox",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "sandbox___de",
    path: "/de/sandbox",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "signin___en",
    path: "/signin",
    meta: signinGPtRMeBw4QMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signin___de",
    path: "/de/signin",
    meta: signinGPtRMeBw4QMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signout___en",
    path: "/signout",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/signout.vue").then(m => m.default || m)
  },
  {
    name: "signout___de",
    path: "/de/signout",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/signout.vue").then(m => m.default || m)
  },
  {
    name: "signup___en",
    path: "/signup",
    meta: signup5xAOa9Jr2IMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "signup___de",
    path: "/de/signup",
    meta: signup5xAOa9Jr2IMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: analyticsVW3i4afqtMMeta?.name,
    path: "/user/analytics",
    meta: analyticsVW3i4afqtMMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/analytics.vue").then(m => m.default || m),
    children: [
  {
    name: "user-analytics___en",
    path: "",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "user-analytics-sessions___en",
    path: "sessions",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: analyticsVW3i4afqtMMeta?.name,
    path: "/de/user/analytics",
    meta: analyticsVW3i4afqtMMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/analytics.vue").then(m => m.default || m),
    children: [
  {
    name: "user-analytics___de",
    path: "",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "user-analytics-sessions___de",
    path: "sessions",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/analytics/sessions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-chatbots-chat-id___en",
    path: "/user/chatbots/chat/:id()",
    meta: _91id_93iKq2nZeBQEMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-chat-id___de",
    path: "/de/user/chatbots/chat/:id()",
    meta: _91id_93iKq2nZeBQEMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/chatbots/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-create___en",
    path: "/user/chatbots/create",
    meta: created3BFIQR5jQMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/chatbots/create.vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-create___de",
    path: "/de/user/chatbots/create",
    meta: created3BFIQR5jQMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/chatbots/create.vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-edit-id___en",
    path: "/user/chatbots/edit/:id()",
    meta: _91id_93mRPea5CiXGMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots-edit-id___de",
    path: "/de/user/chatbots/edit/:id()",
    meta: _91id_93mRPea5CiXGMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/chatbots/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots___en",
    path: "/user/chatbots",
    meta: indexNU9fC4xomrMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/chatbots/index.vue").then(m => m.default || m)
  },
  {
    name: "user-chatbots___de",
    path: "/de/user/chatbots",
    meta: indexNU9fC4xomrMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/chatbots/index.vue").then(m => m.default || m)
  },
  {
    name: "user-documents___en",
    path: "/user/documents",
    meta: documentst3pKOXThD3Meta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/documents.vue").then(m => m.default || m)
  },
  {
    name: "user-documents___de",
    path: "/de/user/documents",
    meta: documentst3pKOXThD3Meta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/documents.vue").then(m => m.default || m)
  },
  {
    name: "user___en",
    path: "/user",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user___de",
    path: "/de/user",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-postcheckout___en",
    path: "/user/postcheckout",
    meta: postcheckoutRqNOz7DDCwMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/postcheckout.vue").then(m => m.default || m)
  },
  {
    name: "user-postcheckout___de",
    path: "/de/user/postcheckout",
    meta: postcheckoutRqNOz7DDCwMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/postcheckout.vue").then(m => m.default || m)
  },
  {
    name: "user-resetPassword___en",
    path: "/user/resetPassword",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: "user-resetPassword___de",
    path: "/de/user/resetPassword",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: settingsrauyA8FyFqMeta?.name,
    path: "/user/settings",
    meta: settingsrauyA8FyFqMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "user-settings-api___en",
    path: "api",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/api.vue").then(m => m.default || m)
  },
  {
    name: "user-settings___en",
    path: "",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-integrations___en",
    path: "integrations",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/integrations.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-legal___en",
    path: "legal",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/legal.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-organization___en",
    path: "organization",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/organization.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-plan___en",
    path: "plan",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: settingsrauyA8FyFqMeta?.name,
    path: "/de/user/settings",
    meta: settingsrauyA8FyFqMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "user-settings-api___de",
    path: "api",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/api.vue").then(m => m.default || m)
  },
  {
    name: "user-settings___de",
    path: "",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-integrations___de",
    path: "integrations",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/integrations.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-legal___de",
    path: "legal",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/legal.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-organization___de",
    path: "organization",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/organization.vue").then(m => m.default || m)
  },
  {
    name: "user-settings-plan___de",
    path: "plan",
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/settings/plan.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-support___en",
    path: "/user/support",
    meta: supportc98SBv7CBdMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/support.vue").then(m => m.default || m)
  },
  {
    name: "user-support___de",
    path: "/de/user/support",
    meta: supportc98SBv7CBdMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/support.vue").then(m => m.default || m)
  },
  {
    name: "user-team___en",
    path: "/user/team",
    meta: teamUOTiHBqqJsMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/team.vue").then(m => m.default || m)
  },
  {
    name: "user-team___de",
    path: "/de/user/team",
    meta: teamUOTiHBqqJsMeta || {},
    component: () => import("/codebuild/output/src14353685/src/DocuChatv2/web-app/pages/user/team.vue").then(m => m.default || m)
  }
]